import { Vue } from 'vue-class-component'
import SysOrder, { SysOrderStorage } from '@/types/SysOrder'
import SysCart from '@/types/SysCart'
import { Orders } from '@/services/OrdersDataService'
import { Competitions } from '@/services/CompetitionsDataService'
import MembersDataService from '@/services/MembersDataService'
import { History } from '@/services/HistoryDataService'
import ClubsDataService from '@/services/ClubsDataService'
import { Events } from '@/services/EventsDataService'
import CommonFunctions from '@/components/Utility/Common'
import router from '@/router'
import TeamsDataService from '@/services/TeamsDataService'
import RegistrationFeeDataService from '@/services/RegistrationFeeDataService'

export default class checkoutSuccess extends Vue {
  finished = false

  public async finishTransaction () : Promise<void> {
    const orderString = localStorage.getItem('order')
    const cartString = localStorage.getItem('cart')

    if (orderString === null) {
      return
    }
    const orderObj = JSON.parse(orderString) as SysOrderStorage
    const timeInSec = (Date.now() / 1000)

    if (cartString === null || (timeInSec - orderObj.timeStamp) > 3600) {
      // If the cart is empty, something have gone wrong
      // The same is the case, if more than hour have elapsed since communication with Bamboa
      // Updates the order_status to "annulleret" ,removes it from localStorage and return
      const updateOrder = {
        ordre_status: 'annulleret'
      }
      try {
        Orders.OrdersDataService.update(orderObj.toString(), updateOrder)
      } catch (err) {
        console.log(err)
      }
      localStorage.removeItem('order')
      return
    }
    const cartArray = JSON.parse(cartString) as SysCart[]

    const promises = []

    const updateOrder = {
      ordre_status: 'godkendt'
    }

    const updateOrderPromise = Orders.OrdersDataService.update(Number(orderObj.orderId).toString(), updateOrder)
    promises.push(updateOrderPromise)

    if (orderObj.clubPurchase) {
      const createClubOrder = {
        klubber_id: 156, // FIXME: Should be the clubId of the member,
        ordre_id: Number(orderObj.orderId)
      }

      const createClubOrderPromise = Orders.ClubOrderDataService.create(createClubOrder)
      promises.push(createClubOrderPromise)
    }

    // Run through the Cart, and do any API calls if necessary
    for (const item of cartArray) {
      if (item.playerRegistration !== null) {
        const createRegistrationPromise = Competitions.CompetitionMemberRegistrationDataService.create(item.playerRegistration)
        promises.push(createRegistrationPromise)
      }
      if (item.licenseRenewal !== null && item.licenseRenewal.memberID !== undefined && Number(item.licenseRenewal.memberID) >= 1) {
        const updateMember = {
          medlem_licens_slut: item.licenseRenewal.newLicenseEndDate
        }

        const createMemberHistory = {
          medlemshistorik_handling: 'Licens fornyelse til: ' + CommonFunctions.toDanishDateString(item.licenseRenewal.newLicenseEndDate, 4),
          medlem_id: item.licenseRenewal.memberID
        }
        const updateMemberLicensePromise = MembersDataService.update(item.licenseRenewal.memberID.toString(), updateMember)
        const createMemberHistoryPromise = History.HistoryDataService.create(createMemberHistory)

        promises.push(updateMemberLicensePromise, createMemberHistoryPromise)
      }
      if (item.clubLicenseRenewal !== null) {
        const updateClub = {
          klubber_kontingent_slut: item.clubLicenseRenewal.newLicenseEndDate
        }

        const updateClubPromise = ClubsDataService.update(item.clubLicenseRenewal.clubID.toString(), updateClub)

        promises.push(updateClubPromise)
      }
      if (item.eventSignUp !== null) {
        const createEventRegistration = {
          medlem_id: item.eventSignUp.memberId,
          event_id: item.eventSignUp.eventId
        }

        const createEventRegistrationPromise = Events.EventRegistrationDataService.create(createEventRegistration)

        promises.push(createEventRegistrationPromise)
      }
      if (item.teamRegistration !== null) {
        const createNewTeam = {
          hold_status: item.teamRegistration.teamStatus,
          hold_holdnavn: item.teamRegistration.teamName,
          klubber_id: (item.teamRegistration.clubId === null ? null : Number(item.teamRegistration.clubId)),
          raekke_id: (item.teamRegistration.rowId === null ? null : Number(item.teamRegistration.rowId)),
          season_id: (item.teamRegistration.seasonId === null ? null : Number(item.teamRegistration.seasonId))
        }

        // console.log('[checkoutSuccess()] createNewTeam = ' + JSON.stringify(createNewTeam))
        const createTeamRegistration = TeamsDataService.create(createNewTeam)
        promises.push(createTeamRegistration)
      }
      if (item.playerRegistration === null && item.licenseRenewal === null && item.clubLicenseRenewal === null && item.eventSignUp === null && item.teamRegistration === null && item.competitionFeeData !== null) {
        let createNewRegistrationFeeEntry: any = null

        if (item.competitionFeeData.playerMemberIds !== undefined && item.competitionFeeData.playerMemberIds.length >= 1) {
          for (let indexPlayer = 0; indexPlayer < item.competitionFeeData.playerMemberIds.length; indexPlayer++) {
            createNewRegistrationFeeEntry = {
              registration_type: 1,
              medlem_id: item.competitionFeeData.playerMemberIds[indexPlayer],
              staevner_id: item.competitionFeeData.competitionId
            }
            const createRegistrationFeePromise = RegistrationFeeDataService.create(item.playerRegistration)
            promises.push(createRegistrationFeePromise)
          }
        }

        if (item.competitionFeeData.foreignPlayerIds !== undefined && item.competitionFeeData.foreignPlayerIds.length >= 1) {
          for (let indexPlayer = 0; indexPlayer < item.competitionFeeData.foreignPlayerIds.length; indexPlayer++) {
            createNewRegistrationFeeEntry = {
              registration_type: 1,
              usp_id: item.competitionFeeData.foreignPlayerIds[indexPlayer],
              staevner_id: item.competitionFeeData.competitionId
            }
            const createRegistrationFeePromise = RegistrationFeeDataService.create(item.playerRegistration)
            promises.push(createRegistrationFeePromise)
          }
        }
      }

      await Promise.all(promises)
        .then((response) => {
          for (const item of response) {
            console.log(item.data)
          }
          this.finished = true
          localStorage.removeItem('order')
          localStorage.removeItem('cart')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  async mounted () : Promise<void> {
    this.finishTransaction()
  }
}
